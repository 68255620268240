<template>
	<div>
		<div class="margin-lg">
			<div class="bg-black padding-tb-sm text-lg text-center" style="letter-spacing:5px">
				员工信息
			</div>
			<div class="bg-white">
				<div>
					<el-row>
						<el-col :span="3" class="margin-top margin-right">
							<el-upload class="avatar-uploader" :action="uploadActionUrl" :show-file-list="false"
								:on-success="handleAvatarSuccess" :on-error="errorUpload">
								<img v-if="ruleForm.avatar" :src="ruleForm.avatar | previewImage" class="avatar" />
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								<div class="bg-grey" style="height: 25px;line-height: 25px;" v-if="!ruleForm.avatar">
									头像上传
								</div>
							</el-upload>
						</el-col>

						<el-col :span="10" class="margin-top margin-left-xl">
							<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
								class="demo-ruleForm" label-position="left">
								<el-form-item label="姓名" prop="full_name">
									<el-input v-model="ruleForm.full_name"></el-input>
								</el-form-item>
								<el-form-item label="性别" prop="sex">
									<el-radio :label="1" v-model="ruleForm.sex ">男</el-radio>
									<el-radio :label="0" v-model="ruleForm.sex ">女</el-radio>
								</el-form-item>
								<el-form-item label="手机号码" prop="phone">
									<el-input v-model="ruleForm.phone "></el-input>
								</el-form-item>
								<el-form-item label="人事状态" prop="personnel_status">
									<el-select v-model="ruleForm.personnel_status " placeholder="请选择"
										style="width: 100%;">
										<el-option :label="item.Name" :value="item.Key"
											v-for="(item,index) of PersonnelMattersList" :key="item.Name">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="工号" prop="code">
									<el-input v-model="ruleForm.code"></el-input>
								</el-form-item>
								
								<el-form-item label="所属组织" prop="org_id">
									<el-button @click="changeDepartment">{{changeText}}</el-button>
									<span class="padding-left-sm">{{SalesMessage}} </span>
								</el-form-item>

								<el-form-item label="岗位/角色" style="width: 200%;">
									<el-radio-group v-model="ruleForm.post_type" @change="ChangeRoleMessage">
										<el-radio class="margin-top-sm" :label="item.id"
											v-for="(item,index) of roleMessage" :key="item.id" @change="changeRole">
											{{item.name}}
										</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-form>
						</el-col>
					</el-row>
				</div>

				<div v-show="!isMore"
					class="more-info flex aligin-items-center justify-content-center text-blue padding-xs bg-z"
					@click="isMore = true">
					<span class="cursor">填写更多人事信息</span>
					<i class="el-icon-caret-bottom margin-left-sm"></i>
				</div>

				<div class="" v-show="isMore">
					<div class="flex aligin-items-center width-100 justify-content-between">

						<el-divider />
						<div class="text-blue less-info" @click="isMore = false">
							<span>收起</span>
							<i class="el-icon-caret-top margin-left-sm"></i>
						</div>
					</div>

					<div class="margin-lr-xl" style="clear: both;overflow: hidden;">
						<el-col :span="8">
							<div>
								<el-form :model="ruleForm" :rules="rules" ref="MoreruleForm" label-width="100px"
									class="demo-ruleForm" label-position="left">
									<el-form-item label="身份证">
										<el-input v-model="ruleForm.id_card" autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="邮箱">
										<el-input v-model="ruleForm.email" autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="政治">
										<el-select v-model="ruleForm.political_outlook" style="width: 100%;">
											<el-option :label="item.label" :value="item.value"
												v-for="(item,index) of political_outlookList" :key="item.label">
											</el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="专业">
										<el-input v-model="ruleForm.major" autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="其他">
										<el-input v-model="ruleForm.remark" autocomplete="off" type="textarea">
										</el-input>
									</el-form-item>
									<el-form-item label="合同日期">
										<el-col>
											<el-date-picker v-model="ruleForm.contract_time_start " type="date"
												placeholder="选择日期" style="width: 44%;" />
											<span class="padding-lr-xs">至</span>
											<el-date-picker v-model="ruleForm.contract_time_end " type="date"
												placeholder="选择日期" style="width: 44%;" />
										</el-col>
									</el-form-item>
									<el-form-item label="转正日期">
										<el-date-picker v-model="ruleForm.confirmation_date " type="date"
											placeholder="选择日期" style="width: 100%;">
										</el-date-picker>
									</el-form-item>
									<el-form-item label="社保">
										<el-radio v-model="ruleForm.is_social_security " :label='true'>是</el-radio>
										<el-radio v-model="ruleForm.is_social_security " :label='false'>否</el-radio>
									</el-form-item>
								</el-form>
							</div>

						</el-col>
						<el-col :span="8">
							<div class=" margin-left-xl ">
								<el-form :model="ruleForm" :rules="rules" ref="MoreruleForm" label-width="100px"
									class="demo-ruleForm" label-position="left">
									<el-form-item label="生日">
										<el-date-picker v-model="ruleForm.birthday" type="date" placeholder="选择日期"
											style="width: 100%;">
										</el-date-picker>
										<div style="height: 25px;">
											<i class="iconfont icon-yuandianxiao text-green "
												style="font-size: 12px;"></i>
											<span class="text-sm text-green">用于年龄计算和员工生日提醒</span>
										</div>
									</el-form-item>
									<el-form-item label="籍贯">
										<el-input v-model="ruleForm.native_place" autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="婚姻:">
										<el-select v-model="ruleForm.marriage" style="width: 100%;">
											<el-option :value="0" label="未婚"></el-option>
											<el-option :value="1" label="已婚未孕"></el-option>
											<el-option :value="2" label="已婚已孕"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="学历:">
										<el-input v-model="ruleForm.education " autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="劳动合同:">
										<el-select v-model="ruleForm.labor_contract " style="width: 100%;">
											<el-option :value="0" label="未签"></el-option>
											<el-option :value="1" label="已签"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="工资卡:">
										<el-input v-model="ruleForm.wages_open_account " autocomplete="off"
											placeholder="开户银行">
										</el-input>
										<el-input v-model="ruleForm.wages_card " autocomplete="off" placeholder="卡号"
											class="margin-top-xs"></el-input>
									</el-form-item>
								</el-form>
							</div>

						</el-col>
						<el-col :span="8">
							<div class=" margin-left-xl">
								<el-form :model="ruleForm" :rules="rules" ref="MoreruleForm" label-width="100px"
									class="demo-ruleForm" label-position="left">
									<el-form-item label="英文名">
										<el-input v-model="ruleForm.english_name " autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="民族">
										<el-input v-model="ruleForm.nation " autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="院校">
										<el-input v-model="ruleForm.user_school " autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="培训经历">
										<el-input v-model="ruleForm.training_experience " autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="标记">
										<el-input v-model="ruleForm.tag " autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="劳动关系">
										<el-select v-model="ruleForm.labor_relations " style="width: 100%;">
											<el-option :value="0" label="全职"></el-option>
											<el-option :value="1" label="兼职"></el-option>
											<el-option :value="2" label="合作"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="单位名称:">
										<el-input v-model="ruleForm.name" autocomplete="off"></el-input>
									</el-form-item>
								</el-form>
							</div>

						</el-col>

					</div>

				</div>
			</div>
		</div>

		<div class="margin-xl">
			<div class="bg-white">
				<div class="padding-xl">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm"
						label-position="left">
						<el-form-item label="登陆系统" prop="allow_login">
							<el-radio v-model="ruleForm.allow_login " :label='true'>允许</el-radio>
							<el-radio v-model="ruleForm.allow_login " :label='false'>禁止</el-radio>
						</el-form-item>
						<div class="flex flex-wrap-wrap">
							<el-form-item label="登陆名" prop="user_name">
								<el-input v-model="ruleForm.user_name" :disabled="num==1?true:false"></el-input>
								<div>
									<i class="iconfont icon-yuandianxiao text-green "></i>
									<span class="text-sm text-green">保存后禁止修改</span>
								</div>
							</el-form-item>
							<el-form-item label="密码" prop="password" style="margin-left: 100px;">
								<el-input type="password" v-model="ruleForm.password" :disabled="num==1?true:false">
								</el-input>

								<el-button size="mini" type="primary" @click="resetPassword" v-if="num==1">重置密码
								</el-button>
							</el-form-item>
						</div>
						<el-form-item label="是否允许打电话" prop="is_call">
							<el-radio :label="true" v-model="ruleForm.is_call">是</el-radio>
							<el-radio :label="false" v-model="ruleForm.is_call">否</el-radio>
						</el-form-item>
						<el-form-item label="坐席电话" prop="seats_phone" v-if="ruleForm.is_call">
							<el-input v-model="ruleForm.seats_phone" style="width:210px;"></el-input>
						</el-form-item>
						<el-form-item label="坐席账号" prop="seats_account" v-if="ruleForm.is_call ">
							<el-input v-model="ruleForm.seats_account" style="width:210px;"></el-input>
						</el-form-item>
						<el-form-item label="坐席密码" prop="seats_password" v-if="ruleForm.is_call ">
							<el-input type="password" v-model="ruleForm.seats_password" style="width:210px;"></el-input>
						</el-form-item>


						<el-form-item label="权限">
							<div class="margin bg-light-gray padding-sm">
								<el-tree ref='tree' :data="AuthorityList" node-key="id" 
									:default-expand-all="true" show-checkbox :props="Props"
									@check-change="handleNodeClick">
								</el-tree>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>

		<div class="margin-xl flex justify-content-center">
			<el-button type="primary" style="margin-left: 20px;" @click="submitForm('ruleForm')">保存</el-button>
			<el-button @click="goNav('/InternalManagement/staff')">取消</el-button>

		</div>

	</div>
</template>

<script>
	export default {
		name: "newEmployee",
		data() {
			var checkMobile = (rule, value, cb) => {
				// 验证手机号的正则表达式
				const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
				// if (regMobile.test(value)) {
				// 	return cb();
				// }
				if (value.length == 11) {
					return cb();
				}
				cb(new Error("请输入合法的手机号"));
			};
			return {
				specify: 1,
				Props: {
					children: "children",
					label: "title",
				},
				listQuery: {
					type: 1,
					PageIndex: 1,
					PageSize: 15,
					Sort: [{
						Field: "id",
						Type: 0
					}]
				},
				num: 0,
				checkList: [],
				count: 1,
				shows: true,
				checked: false,
				imageUrl: '',
				changeText: '选择',
				meaagesNames: [],
				SalesMessage: '', //选择的销售团队
				isMore: false,
				RoleDicIds: [], //角色权限下的ids
				AccountTypeList: [], //账号类型
				PersonnelMattersList: [], //人事状态
				// organizationList: [], //所有校区
				type_school: false,
				class_schoolId: [], //选择的指定校区
				governing_campu: [], //选的管辖校区
				type: [], //账号类型
				checkedRoleIds: [], //选中的角色id
				political_outlookList: [{
					label: '未知',
					value: 0,
				}, {
					label: '群众',
					value: 1,
				}, {
					label: '团员',
					value: 2,
				}, {
					label: '预备党员',
					value: 3,
				}, {
					label: '党员',
					value: 4,
				}, ],
				listQuery1: {
					HasRole: false,
					module: '',
					TotalCount: 0,
					PageIndex: 1,
					PageSize: 15
				},
				listQuerys: {
					module: 'RolePermissions',
					parentId: 365,
				},
				RoleQuery: {
					type: 1,
					"PageIndex": 1,
					"PageSize": 15,
					"Sort": [{
						"Field": "id",
						"Type": 0
					}]
				},
				AuthorityList: [], //所有的权限列表
				roleMessage: [], //管辖学校下的角色
				// EmployeeDetail:{},//员工编辑时传过来的员工信息
				ruleForm: {
					password: '', //密码
					type: '', //账号类型
					personnel_status: 0, //人事状态
					political_outlook: 0, //政治面貌
					dic_ids: [], //权限ids
					user_name: '', //登录名
					full_name: '', //姓名
					phone: '', //手机号
					sex: 0, //性别  0女 1男
					email: '', //邮箱
					contract_number: '', //合同编号
					contract_time_start: '', //合同开始时间
					contract_time_end: '', //合同到期时间
					adress: '', //地址
					avatar: '', //头像
					code: '', //编号或者工号
					seats_account: '', //坐席账号
					seats_phone: '', //座机电话
					seats_password: '', //坐席密码
					allow_login: true, //是否允许登陆系统
					id_card: '', //身份证号码
					birthday: '', //生日
					english_name: '', //英文名字
					native_place: '', //籍贯
					nation: '', //民族
					marriage: '', //婚姻状态  0=未婚 1=已婚未孕 2=已婚已孕
					user_school: '', //院校
					major: '', //专业
					education: '', //学历
					training_experience: '', //培训经历
					remark: '', //备注
					tag: '', //标记
					labor_contract: '', //是否签订劳动合同  0=未签 1=已签
					labor_relations: '', //劳动关系  0=全职  1=兼职  2=合作
					confirmation_date: '', //转正日期
					wages_card: '', //工资卡
					wages_open_account: '', //开户银行
					is_social_security: false, //是否办理医保
					org_id: 0, // 部门id ,
					post_type: 0, //岗位角色id
					role_dic_id: '', //岗位数据字典根id
					is_call:false,//是否允许打电话
				},
				rules: {
					full_name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择账号类型',
						trigger: 'change'
					}],

					sex: [{
						required: true,
						message: '请选择性别',
						trigger: 'change'
					}],
					phone: [{
							required: true,
							message: "手机号码不能为空",
						},
						{
							validator: checkMobile,
							message: "请输入正确的手机号码",
							trigger: "blur"
						}
					],
					personnel_status: [{
						required: true,
						message: '请选择人事状态',
						trigger: 'change',
					}],
					is_class_teacher: [{
						required: true,
						message: '是否为上课教师',
						trigger: 'change'
					}],
					code: [{
						required: true,
						message: '请输入工号',
						trigger: 'blur'
					}],
					org_id: [{
						required: true,
						message: '请选择所属部门',
						trigger: 'change'
					}],
					allow_login: [{
						required: true,
						message: '请选择是否允许登陆系统',
						trigger: 'change'
					}],
					user_name: [{
						required: true,
						message: '请输入登陆名',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],
				}
			}
		},
		created() {
			this.getType()
			this.getRoleList()
			this.getEmployeeMessage()
			this.roleQueryList()
		},
		methods: {
			goNav(url) {
				localStorage.removeItem('EmployeeID');
				this.$router.push(url)
			},
			getEmployeeMessage() {
				let EmployeeId = localStorage.getItem('EmployeeID')

				if (EmployeeId == "undefined") {
					return
				} else {
					this.num = 1;
					let query = {
						id: EmployeeId
					}
					//根据员工id出查询详情
					this.API.accountDetail(query).then(res => {
						this.ruleForm = res.data
						this.ruleForm.password = '123456'
						if(this.ruleForm.org_name){
							this.changeText = this.ruleForm.org_name+' (修改)';
						}
						this.changeRole(this.ruleForm.post_type)
						let Arr = []
						Arr.push(this.ruleForm.governing_campus)
						this.roleQueryList(Arr)
						this.checkedRoleIds = this.ruleForm.post_type_dicids

						console.log(this.ruleForm)
					})

				}
			},
			//重置账号密码
			resetPassword() {
				this.$confirm('密码将重置为123456?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let list = {
						userid: this.ruleForm.id
					}
					this.API.accountResetPassword(list).then(res => {
						if (res.code == 200) {
							this.$message.success("重置成功")
						}
					})
				}).catch(() => {

				});
			},

			//获取指定校区下的角色和岗位列表
			roleQueryList() {
				let list = []
				this.getRoleQuery({
					type: [0, 1], //类型 0 角色 1 职位/岗位 ,
					PageInde: 1, //当前页 ,
					PageSize: 10000,
				}).then((res) => {
					this.roleMessage = list.concat(list, res.data.rows)
				});
			},
			//选定角色后，绑定已有的权限
			changeRole(e) {
				console.log(e)
				this.checkedRoleIds=[]
				this.ruleForm.role_dic_id = 365
				let listQuery = {
					role_id: e,
					dic_parent_id: 365,
				}
				this.API.getRoleDicIds(listQuery).then(res => {
					this.checkedRoleIds = res.data || [];
					this.$refs.tree.setCheckedKeys(this.checkedRoleIds);
				})
			},
			//角色徐权限的选择
			handleNodeClick(data, checked, indeterminate) {
				let index = this.checkedRoleIds.findIndex((t) => t == data.id);
				let index1 = this.ruleForm.dic_ids.findIndex((t) => t == data.id)
				if (checked) {
					if (index == -1) {
						this.checkedRoleIds.push(data.id);
						this.ruleForm.dic_ids.push(data.id)
					}
				} else {
					if (index > -1) {
						this.checkedRoleIds.splice(index, 1);
						this.ruleForm.dic_ids.splice(index1, 1)
					}
				}
			},

			getType() {
				//账号类型
				this.API.SystemGetSettings('AccountType', false).then(res => {
					this.AccountTypeList = res
				})
				//人事状态类型 
				this.API.SystemGetSettings('PersonnelMatters', false).then(res => {
					this.PersonnelMattersList = res
				})
				// //获取所有校区
				// this.API.OrganizationList(this.listQuery).then(res => {
				// 	this.organizationList = res.data.rows
				// })
			},
			//上传的头像
			handleAvatarSuccess(res, file) {
				this.ruleForm.avatar = res.data.src
			},
			//头像上失败
			errorUpload(err, file) {
				this.$message.error("上传失败,请重新登陆")
			},
			//创建账号的显示
			changeStatus() {
				this.shows = !this.shows
			},
			// //获取角色  有误----应该获取接口的数据
			getRoleQuery(data) {
				return new Promise((resolve, reject) => {
					this.API.roleQuery(data).then((res) => {
						resolve(res);
					});
				});
			},
			//获取权限
			getRoleList(parentId) {
				let listQuery = {
					...this.listQuerys
				};
				if (parentId) {
					listQuery.parentId = parentId;
				}
				this.getRoleTree(listQuery).then((res) => {
					if (parentId) {
						this.AuthorityList.find((t) => t.id == parentId).children = res;
					} else {
						this.AuthorityList = res;
					}
					this.AuthorityList = JSON.parse(JSON.stringify(this.AuthorityList));
					res.forEach((item) => {
						if (item.leaf) {
							this.getRoleList(item.id);
						}
					});
				});
			},
			getRoleTree(data) {
				return new Promise((resolve, reject) => {
					this.$Dictionary.GetDictionaryTree(data).then(res => {
						resolve(res.data)
					});
				})
			},
			//组织的选择
			changeDepartment(index) {
				this.$Dialog.selectDepartment().then(res => {
					this.changeText = "重新选择"
					this.SalesMessage = res.name
					this.ruleForm.org_id = res.id
					this.getRoleQuery(this.RoleQuery)
				})
			},
			//选择的部门
			ChangeRoleMessage(val) {
				this.ruleForm.post_type = val
			},
			submitForm(formName) {
				console.log(this.ruleForm)
				this.loading=true
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.num == 0) {
							this.API.AccountCreate(this.ruleForm).then(res => {
								if (res.code == 200) {
									this.$message.success("员工创建成功")
									this.$router.push('/InternalManagement/staff')
									this.loading=false
								}
							})
						} else {
							this.API.accountUpdate(this.ruleForm).then(res => {
								if (res.code == 200) {
									this.$message.success(res.message)
									localStorage.removeItem('EmployeeID');
									this.$router.push('/InternalManagement/staff')
									this.loading=false
								}
							})

						}
					}
				});
			},
		}
	}
</script>

<style lang="scss">
	.avatar-uploader .el-upload {
		width: 100%;
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		height: 190px;
		line-height: 170px;
		text-align: center;
	}

	.avatar {
		width: 100%;
		height: 190px;
	}

	.el-form-item__label {
		line-height: 35px;
	}

	.el-tree {
		background: #F2F6FC;
	}


	.less-info:hover {
		cursor: pointer;
	}
</style>
